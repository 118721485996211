import { defineMessages } from 'react-intl.macro';

import { paths } from '@/routing/paths';

const breadcrumbMessages = defineMessages({
    classesPage: {
        id: 'breadcrumb.classesPage',
        defaultMessage: 'Class list',
    },
    classDetailPage: {
        id: 'breadcrumb.classDetailPage',
        defaultMessage: 'Class detail',
    },
    classCreatePage: {
        id: 'breadcrumb.classCreatePage',
        defaultMessage: 'Class create',
    },
    classEditPage: {
        id: 'breadcrumb.classEditPage',
        defaultMessage: 'Class edit',
    },
    classPage: {
        id: 'breadcrumb.classPage',
        defaultMessage: 'Class',
    },
    labPage: {
        id: 'breadcrumb.labPage',
        defaultMessage: 'Lab',
    },
});

export const classesPageBreadcrumb = (formatMessage) => ({
    text: formatMessage(breadcrumbMessages.classesPage),
    href: paths.classListPage,
});

export const classDetailPageBreadcrumb = (formatMessage, id) => ({
    text: formatMessage(breadcrumbMessages.classDetailPage),
    href: paths.classDetailPage(id),
});

export const classCreatePageBreadcrumb = (formatMessage) => ({
    text: formatMessage(breadcrumbMessages.classCreatePage),
    href: paths.classPage('new'),
});

export const classEditPageBreadcrumb = (formatMessage, id) => ({
    text: formatMessage(breadcrumbMessages.classEditPage),
    href: paths.classEditPage(id),
});

export const classPageBreadcrumb = (formatMessage, id) => ({
    text: formatMessage(breadcrumbMessages.classPage),
    href: paths.classPage(id),
});

export const labPageBreadcrumb = (formatMessage, classroomId, labId) => ({
    text: formatMessage(breadcrumbMessages.labPage),
    href: paths.labPage(classroomId, labId),
});
