import { Navigate, createBrowserRouter, RouterProvider } from 'react-router-dom';

import {
    ClassPage,
    ClassDetailPage,
    ClassEditPage,
    ClassCreatePage,
    ErrorPage,
    LabPage,
    ProvidersPage,
    ClassListPageV2,
    AttendancePage,
} from '@/containers';
import { App, appLoader } from '@/containers/App';
import { parameters, redirect } from '@/routing/paths';
import { AppProviders } from '@/utils/Providers';

import type { RouteObject } from 'react-router-dom';

const classPages: RouteObject[] = [
    {
        path: 'new',
        element: <ClassCreatePage />,
    },
    {
        path: parameters.classroomId,
        element: <ClassPage />,
    },
    {
        path: `${parameters.classroomId}/detail`,
        element: <ClassDetailPage />,
    },
    {
        path: `${parameters.classroomId}/edit`,
        element: <ClassEditPage />,
    },
    {
        path: `${parameters.classroomId}/attendance`,
        element: <AttendancePage />,
    },
    {
        path: `${parameters.classroomId}/labs/${parameters.labNumber}`,
        element: <LabPage />,
    },
];

export const routerConfig = (globals = window) => [
    {
        path: '/',
        element: (
            <AppProviders>
                <App />
            </AppProviders>
        ),
        errorElement: <ErrorPage />,
        loader: appLoader,
        children: [
            {
                index: true,
                element: <Navigate replace to={redirect(globals)} />,
            },
            {
                path: 'callback',
                element: <Navigate replace to={redirect(globals)} />,
            },
            {
                path: 'providers',
                element: <ProvidersPage />,
            },
            {
                path: 'classes',
                children: [
                    {
                        index: true,
                        element: <ClassListPageV2 />,
                    },
                    ...classPages,
                ],
            },
            // NOTE: the /class/* paths are here for backwards compatibility reasons
            // would like to remove them eventually and use /classes/* as the convention
            {
                path: 'class',
                children: classPages,
            },
        ],
    },
];

export const AppRouter = ({ globals = window }) => (
    <RouterProvider router={createBrowserRouter(routerConfig(globals))} />
);
