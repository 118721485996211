import { Container, Header, Button } from '@amzn/awsui-components-react';
import { Auth } from 'aws-amplify';
import { Helmet } from 'react-helmet';
import { useIntl } from 'react-intl';

import '@/containers/identityErrorPage/IdentityErrorPage.scss';
import {
    getFormattedIdpName,
    IdpNames,
} from '@/containers/identityErrorPage/IdentityErrorPage.utils';
import { redirectToLoginPage } from '@/modules';
import { useUserInfo } from '@/utils';

import messages from './SplitLoginPage.messages';

const SplitLoginPage = () => {
    const { formatMessage } = useIntl();
    const { email, public_provider_name, originalSignInMethodName } = useUserInfo();

    const currentSignInMethodMessage = getFormattedIdpName(public_provider_name, formatMessage);
    const originalSignInMethodMessage = getFormattedIdpName(
        originalSignInMethodName,
        formatMessage,
    );

    const handleRedirect =
        originalSignInMethodName === IdpNames.Unknown
            ? async () => {
                  await Auth.signOut();
              }
            : async () => {
                  redirectToLoginPage(false, originalSignInMethodName);
              };

    return (
        <div className='full-page-modal'>
            <Helmet>
                <title>{formatMessage(messages.pageTitle)}</title>
            </Helmet>
            <div className='container-wrapper'>
                <Container header={<Header variant='h2'>{formatMessage(messages.title)}</Header>}>
                    {formatMessage(messages.mainMessage, {
                        currentSignInMethod: currentSignInMethodMessage,
                        originalSignInMethod: originalSignInMethodMessage,
                        userEmail: email,
                        bold: (str) => <b>{str}</b>,
                    })}
                    <div className='button-wrapper'>
                        <Button
                            fullWidth
                            variant='primary'
                            onClick={handleRedirect}
                            data-testid='button-split-login-redirect'
                        >
                            {formatMessage(messages.buttonRedirect, {
                                signInMethod: originalSignInMethodMessage,
                            })}
                        </Button>
                    </div>
                </Container>
            </div>
        </div>
    );
};

export default SplitLoginPage;
