import 'moment/min/locales';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import { configureAmplify } from '@/modules';
import { routerConfig } from '@/routing/Router';
import { LangLocaleProvider } from '@/utils/locale';

configureAmplify();

createRoot(document.getElementById('root')).render(
    <React.StrictMode>
        <LangLocaleProvider>
            <RouterProvider router={createBrowserRouter(routerConfig())} />
        </LangLocaleProvider>
    </React.StrictMode>,
);
