import { useQuery } from 'react-query';

import { getLearningActivity } from '@/modules';

import { useFlags } from '../utils';

export const useGetLearningActivity = (classData) => {
    const flags = useFlags();
    const classroomId = classData?.classroom?.classroomId;
    const providerArn = classData?.classroom?.providerArn;
    const learningActivityID = classData?.classroom?.learningActivityID;
    return useQuery(
        [classroomId, providerArn, learningActivityID, 'getLearningActivity'],
        () => getLearningActivity(classroomId, providerArn, learningActivityID),
        {
            refetchOnWindowFocus: false,
            enabled: flags.attendanceMarking && !!learningActivityID,
        },
    );
};
