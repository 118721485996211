import { createContext, useState, useEffect, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import { paths } from '@/routing/paths';

export const ProviderCtx = createContext();

export const PROVIDER_KEY = 'aws.beaker.provider';

export const ProviderCtxProvider = ({ globals = window, children }) => {
    const [provider, setProvider] = useState(
        JSON.parse(globals.localStorage.getItem(PROVIDER_KEY)),
    );
    useEffect(() => {
        if (!provider) return;
        globals.localStorage.setItem(PROVIDER_KEY, JSON.stringify(provider));
    }, [provider, globals.localStorage]);

    return (
        <ProviderCtx.Provider
            value={{
                provider,
                setProvider,
            }}
        >
            {children}
        </ProviderCtx.Provider>
    );
};

export const useProvider = () => {
    const { provider } = useContext(ProviderCtx);
    const navigate = useNavigate();
    const { pathname } = useLocation();

    useEffect(() => {
        if (!provider) {
            navigate(paths.providersPage, { state: { referrer: pathname } });
        }
    }, [provider, navigate, pathname]);

    return provider;
};

export const clearProviderOutsideCtx = (globals) => globals.localStorage.removeItem(PROVIDER_KEY);
