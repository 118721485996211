import { ErrorBoundary } from 'react-error-boundary';
import { useLoaderData, Outlet } from 'react-router-dom';

import { NavBar, ErrorFallback, Footer } from '@/components';
import { SuspenseLoader } from '@/components/loader/Loader';
import { ResourceConsolidationPage, SplitLoginPage } from '@/containers';
import { checkResourceConsolidation } from '@/utils/resetResources';
import { useUserInfo } from '@/utils/userInfo';

import './index.css';

type ConsolidationResponse = {
    hasResourcesConsolidated: boolean;
    isRedirecting: boolean;
};

type LoaderData = {
    consolidation: Promise<ConsolidationResponse>;
};

export const appLoader = () => ({
    consolidation: checkResourceConsolidation(),
});

export const App = () => {
    const { consolidation } = useLoaderData() as LoaderData;
    const { originalSignInMethodName } = useUserInfo();

    return (
        <>
            <NavBar />
            <ErrorBoundary FallbackComponent={ErrorFallback}>
                <SuspenseLoader resolve={consolidation}>
                    {({ hasResourcesConsolidated, isRedirecting }: ConsolidationResponse) => {
                        if (isRedirecting) {
                            return <div data-testid='redirecting' />;
                        }

                        if (originalSignInMethodName) {
                            return <SplitLoginPage />;
                        }

                        return hasResourcesConsolidated ? (
                            <Outlet />
                        ) : (
                            <ResourceConsolidationPage />
                        );
                    }}
                </SuspenseLoader>
            </ErrorBoundary>
            <Footer />
        </>
    );
};
