import { Authenticator } from '@/components';
import { ClassroomProvider } from '@/data/ClassroomContext';
import { GilmoreHealthProvider } from '@/data/GilmoreHealthContext';
import { ProviderCtxProvider } from '@/data/ProviderContext';
import { FlagContextProvider } from '@/utils';
import { AppNotificationsProvider } from '@/utils/appNotifications';
import { QueryClientProviderWrapper } from '@/utils/QueryClientProvider';
import { UserInfoProvider } from '@/utils/userInfo';

const Provides = ({ contexts = [], children }) =>
    contexts.reduceRight(
        (reactNode, [Wrapper, props = {}]) => <Wrapper {...props}>{reactNode}</Wrapper>,
        children,
    );

export const AppProviders = ({ children, globals = window, classroom = {}, gilmore = [] }) => (
    <Provides
        contexts={[
            [FlagContextProvider],
            [ClassroomProvider, { value: classroom }],
            [Authenticator, { globals }],
            [QueryClientProviderWrapper],
            [ProviderCtxProvider, { globals }],
            [UserInfoProvider],
            [GilmoreHealthProvider, { value: gilmore }],
            [AppNotificationsProvider],
        ]}
    >
        {children}
    </Provides>
);
