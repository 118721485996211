import { Container, Header, Button } from '@amzn/awsui-components-react';
import { get as _get } from 'lodash';
import { useContext, useState, useCallback, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useIntl } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';

import { Loader } from '@/components';
import { ProviderCtx } from '@/data/ProviderContext';
import { getProviders } from '@/modules/api';
import { paths } from '@/routing/paths';
import { useAcceptInvitations } from '@/utils/useAcceptInvitations';

import messages from './ProvidersPage.messages';

import './ProvidersPage.css';

export const ProviderType = Object.freeze({
    Direct: 'DIRECT',
    ATP: 'ATP',
});

// when data has a value, it means we expect a different response
// hence the amount of time to wait
const waitForConsistency = (data) =>
    new Promise((res) => {
        setTimeout(res, data ? 2000 : 1);
    });

const ProvidersPage = () => {
    const { formatMessage } = useIntl();
    const { setProvider } = useContext(ProviderCtx);
    const { search, state } = useLocation();
    const navigate = useNavigate();
    const [providerList, providerListSet] = useState();
    const [error, errorSet] = useState(false);
    const acceptedInvitations = useAcceptInvitations();

    const onProviderSelect = useCallback(
        (data) => {
            const path = paths.landingPage;
            setProvider(data);
            navigate(_get(state, 'referrer', path));
        },
        [navigate, setProvider, state],
    );

    useEffect(() => {
        if (acceptedInvitations && providerList) {
            if (acceptedInvitations.length === 0 && providerList.length) {
                // no need to get providers again if there were no new accepted invitations
                return;
            } else if (providerList.length === 0) {
                // stop showing the "no associated providers" message
                // because some are being fetched
                providerListSet(null);
            }
        }

        waitForConsistency(acceptedInvitations).then(() => {
            getProviders()
                .then((data) => {
                    providerListSet(data);
                })
                .catch((_err) => {
                    if (acceptedInvitations) {
                        errorSet(true);
                    }
                });
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [acceptedInvitations]);

    useEffect(() => {
        if (providerList && providerList.length === 1) {
            onProviderSelect(providerList[0]);
        } else if (providerList && search) {
            // Auto-select the provider from the query parameter if one is specified
            const providerArn = new URLSearchParams(search).get('providerArn');
            const found = providerList.find((p) => p.arn === providerArn);
            if (found) {
                onProviderSelect(found);
            }
        }
    }, [providerList, onProviderSelect, search]);

    return (
        <div className='providers-page'>
            <Helmet>
                <title>{formatMessage(messages.pageTitle)}</title>
            </Helmet>
            <Container
                style={{ width: '100%' }}
                header={
                    <Header variant='h2'>{formatMessage(messages.partnerSelectionTitle)}</Header>
                }
            >
                <Loader
                    isLoading={!providerList}
                    message={formatMessage(messages.loadingPartners)}
                    fallback={() => formatMessage(messages.loadingFailure)}
                    backgroundColor='transparent'
                    hasError={error}
                >
                    {providerList && providerList.length ? (
                        <ul className='providers-list'>
                            {providerList.map((data) => (
                                <li key={data.arn}>
                                    <Button onClick={() => onProviderSelect(data)}>
                                        {data.name}
                                    </Button>
                                </li>
                            ))}
                        </ul>
                    ) : (
                        <p>{formatMessage(messages.noPartners)}</p>
                    )}
                </Loader>
            </Container>
        </div>
    );
};

export default ProvidersPage;
