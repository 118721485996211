import { API, Hub, Cache, graphqlOperation } from 'aws-amplify';
import { createHash } from 'crypto-browserify';
import { addMilliseconds } from 'date-fns';
import { omit, uniq, isEmpty, endsWith } from 'lodash';
import moment from 'moment-timezone';

import mockListClassrooms from '@/data/classList.mock';
import {
    getLearningActivity as getLearningActivityQuery,
    getClassAttendance as getClassAttendanceQuery,
} from '@/graphql/custom';
import {
    clientLog,
    createClassroomRelationships,
    updateClassroomV2,
    createClassroomV2,
    cancelClassroom as cancelClassroomMutation,
    updateTrainingPermissions as updateTrainingPermissionsMutation,
    createStudentTrainings,
    addStudentsToClassroomRoster as addStudentsToClassroomRosterMutation,
    excludeUser as excludeUserMutation,
    cancelStudentTrainings,
    putTrainingConfiguration as putTrainingConfigurationMutation,
    markAttendance as markAttendanceMutation,
    resetResources,
} from '@/graphql/mutations';
import {
    getCourseVersions,
    getCourseVersion,
    getProviderArns,
    getProvider,
    listUserRelationships,
    listClassroomRelationships,
    listUserClassrooms,
    listCourses,
    listProviderClassrooms,
    listMajorCourseVersions,
    getIngressUrl as getIngressUrlQuery,
    listProviderClassroomsV2 as listProviderClassroomsV2Query,
    listInstructorClassroomsV2 as listInstructorClassroomsV2Query,
    listProviderReports as listProviderReportsQuery,
    getProviderReport as getProviderReportQuery,
    getServiceSchedule as scheduledEventsQuery,
    getTrainingConfiguration as getTrainingConfigurationQuery,
    requiresReset as requiresResetQuery,
    courseVirtualSKUInfo as getCourseVirtualSKUInfoQuery,
} from '@/graphql/queries';
import { onUpdateTrainingPermissions } from '@/graphql/subscriptions';
import { createLogMessage } from '@/utils/createLogMessage';
import { AppError, AUTH_ERROR, FETCH_ERROR_NAME, createException } from '@/utils/error';
import { parseError } from '@/utils/gonzo-error-parse-utils';
import { sortCourseVersions } from '@/utils/helpers';

import { signInWithCurrentProvider } from './auth/authOTPUtils';
import { SESSION_EXPIRED } from './session';

const HTTP_STATUS = {
    BAD_REQUEST: 400,
    UNAUTHORIZED: 401,
    FORBIDDEN: 403,
    NOT_FOUND: 404,
    CONFLICT: 409,
    INTERNAL_SERVER_ERROR: 500,
    NOT_IMPLEMENTED: 501,
};

const EXPIRATION_MS = 3600000; // 1 hour
const MAX_MAJOR_COURSE_VERSIONS = 20; // https://code.amazon.com/reviews/CR-97924654

function getHash(obj = {}, hashAlgorithm = 'sha256', encoding = 'hex') {
    const cryptoHash = createHash(hashAlgorithm);
    const stringifiedObj = JSON.stringify(obj);
    return cryptoHash.update(stringifiedObj).digest(encoding);
}

async function handleHttpErrorResponse(
    response = {},
    params = {},
    redirectOn404 = true,
    silenceClientErrorsForStatusCodes = [],
    context = undefined,
) {
    // This is what Amplify "throws" if the session is expired/invalid.
    if (response === 'No current user') {
        // Dispatch message to hub
        return Hub.dispatch(SESSION_EXPIRED);
    } else if (typeof response === 'string') {
        throw new AppError(`Unhandled amplify error: ${response}`, { type: AUTH_ERROR, ...params });
    }
    const { errors } = response;
    const { message = '', errorType, path = [] } = errors[0];
    if (message) {
        const statusCode = parseInt(message.split('::')[1]);
        if (silenceClientErrorsForStatusCodes.includes(statusCode)) {
            return;
        }

        switch (statusCode) {
            case HTTP_STATUS.UNAUTHORIZED:
                Hub.dispatch(SESSION_EXPIRED);
                break;
            case HTTP_STATUS.NOT_FOUND:
                // For now just a 404 page till we have UX direction
                if (redirectOn404) {
                    window.location.replace('/error');
                    return;
                } else {
                    throw createException(statusCode, path[0]);
                }
            case HTTP_STATUS.INTERNAL_SERVER_ERROR:
                throw new AppError(`operation ${path[0]} returned a 500`, {
                    status: statusCode,
                    type: FETCH_ERROR_NAME,
                    ...params,
                });
            case HTTP_STATUS.FORBIDDEN:
                // Redirect to OTP sign in if the email is unverified, otherwise throw an exception
                try {
                    const invalidFields = parseError(JSON.parse(errorType));
                    if (
                        Array.isArray(invalidFields?.email) &&
                        invalidFields.email.some((error) => error.code === 'UnverifiedEmailError')
                    ) {
                        return await signInWithCurrentProvider();
                    }
                } catch (error) {
                    console.log('Unknown error', error);
                    throw createException(statusCode, path[0]);
                }
            // eslint-disable-next-line no-fallthrough
            case HTTP_STATUS.BAD_REQUEST:
                // Redirect user to sign in via their original sign in method if the current sign-in method differs
                try {
                    const invalidFields = parseError(JSON.parse(errorType));
                    if (
                        Array.isArray(invalidFields?.email) &&
                        invalidFields.email.some(
                            (error) => error.code === 'PreviousIdentityDetected',
                        )
                    ) {
                        const originalSignInMethodName = invalidFields.email.find(
                            (error) => error.code === 'PreviousIdentityDetected',
                        ).values.originalSignInMethodName;

                        if (
                            !context ||
                            !context.setOriginalSignInMethodName ||
                            typeof context.setOriginalSignInMethodName !== 'function'
                        ) {
                            console.log('No context has been passed for handling this error');
                            throw createException(statusCode, path[0]);
                        } else {
                            const { setOriginalSignInMethodName } = context;
                            setOriginalSignInMethodName(originalSignInMethodName);
                            return;
                        }
                    }
                } catch (error) {
                    console.log('Unknown error', error);
                    throw createException(statusCode, path[0]);
                }
            // eslint-disable-next-line no-fallthrough
            case HTTP_STATUS.NOT_IMPLEMENTED:
            case HTTP_STATUS.CONFLICT:
                throw createException(statusCode, path[0]);
            default:
                throw errorType;
        }
    }
    throw errorType;
}

/**
 * Execute a GraphQL operation (query) and return results.
 * Options can be passed to enable response cache
 */
async function executeRequest({
    operation,
    params = {},
    onSubscribe = undefined,
    options = {
        useCache: false,
        redirectOn404: true,
        silenceClientErrorsForStatusCodes: [],
        context: undefined,
    },
}) {
    const newOperation = graphqlOperation(operation, params);
    const { useCache, redirectOn404 } = options;
    let results;
    try {
        if (onSubscribe) {
            results = await API.graphql(newOperation).subscribe({
                error: (err) => {
                    sendClientLog({
                        type: 'SubscriptionError',
                        metrics: {
                            clientSubscriptionError: [1, 'Count'],
                        },
                        attributes: {
                            errorMessage: JSON.stringify(err),
                        },
                    });
                },
                next: (event) => {
                    onSubscribe(event.value.data);
                },
            });
        } else {
            const operationKey = getHash(newOperation);
            if (Cache.getItem(operationKey)) {
                results = Cache.getItem(operationKey);
            } else {
                results = await API.graphql(newOperation);
                if (useCache) {
                    const expiration = addMilliseconds(new Date(), EXPIRATION_MS);
                    Cache.setItem(operationKey, results, {
                        expires: expiration.getTime(),
                    });
                }
            }
        }
    } catch (error) {
        await handleHttpErrorResponse(
            error,
            params,
            redirectOn404,
            options.silenceClientErrorsForStatusCodes,
            options.context,
        );
    }

    return results?.data;
}

const listAuthorizedCourses = (providerArn) =>
    executeRequest({
        operation: listCourses,
        params: {
            input: {
                providerArn,
            },
        },
    }).then((r) => r.listCourses.courses);

const listMajorVersions = (grimsbyCourseId, providerArn) =>
    executeRequest({
        operation: listMajorCourseVersions,
        params: {
            input: {
                providerArn,
                grimsbyCourseId,
                maxResults: MAX_MAJOR_COURSE_VERSIONS,
            },
        },
    }).then((r) => r.listMajorCourseVersions.collections);

async function getAllCourseVersions(courseId, providerArn, langLocale) {
    if (!courseId) return [];
    let courseVersions = [];
    let nextToken;
    do {
        const { getCourseVersions: payload } = await executeRequest({
            operation: getCourseVersions,
            params: {
                input: {
                    courseId,
                    nextToken,
                    providerArn,
                    langLocale,
                },
            },
        });
        courseVersions = courseVersions.concat(payload.courseVersions);
        nextToken = payload.nextToken;
    } while (nextToken);

    return sortCourseVersions(courseVersions);
}

async function getCourseVersionDetails(courseVersionId, providerArn) {
    if (!courseVersionId) return [];
    const { getCourseVersion: payload } = await executeRequest({
        operation: getCourseVersion,
        params: { courseVersionId, providerArn },
    });
    return payload.courseVersion;
}

async function getProviderData(providerArn) {
    const result = await executeRequest({
        operation: getProvider,
        params: { input: { providerArn } },
        options: {
            redirectOn404: false,
            silenceClientErrorsForStatusCodes: [HTTP_STATUS.NOT_FOUND],
        },
    });

    if (!result) {
        return null;
    }

    const { getProvider: payload } = result;

    let requiresSubProvider = false;
    if (payload.provider.subProviderData && !isEmpty(payload.provider.subProviderData)) {
        requiresSubProvider =
            !payload.provider.subProviderData[0].providerMetaData?.isParentProvider;
    }
    return {
        ...payload.provider,
        requiresSubProvider,
    };
}

async function getProviders() {
    const {
        getProviderArns: { providers },
    } = await executeRequest({
        operation: getProviderArns,
    });

    const uniqProviders = uniq(providers.filter((provider) => !endsWith(provider, ',')));

    const providerData = await Promise.all(uniqProviders.map(getProviderData));
    return providerData.filter(Boolean);
}

async function sendClientLog(payload) {
    return executeRequest({
        operation: clientLog,
        params: {
            input: {
                body: JSON.stringify(
                    await createLogMessage({
                        payload,
                    }),
                ),
            },
        },
    });
}

async function getUserRoles(providerArn) {
    if (!providerArn) {
        return [];
    }
    try {
        const { listUserRelationships: payload } = await executeRequest({
            operation: listUserRelationships,
            params: {
                input: { providerArn },
            },
        });
        return payload.relationships;
    } catch (err) {
        if (err?.statusCode === HTTP_STATUS.NOT_IMPLEMENTED) {
            return [];
        }
        throw err;
    }
}

const addInstructorsToClassroom = (instructors, classroomId, providerArn) =>
    executeRequest({
        operation: createClassroomRelationships,
        params: {
            input: {
                classroomId,
                providerArn,
                relationship: 'instructor',
                users: instructors.map((email) => ({ email })),
            },
        },
    }).then(({ createClassroomRelationships: response }) => response);

const updateClassroom = async (data) => {
    const input = omit(data, 'instructors');
    const { updateClassroomV2: payload } = await executeRequest({
        operation: updateClassroomV2,
        params: { input },
    });
    return payload;
};

const createClassroom = async (data) => {
    const input = omit(data, 'instructors');
    const { createClassroomV2: payload } = await executeRequest({
        operation: createClassroomV2,
        params: { input },
    });
    return payload;
};

export async function cancelClassroom(classroomId, providerArn) {
    const { cancelClassroomMutation: payload } = await executeRequest({
        operation: cancelClassroomMutation,
        params: { classroomId, providerArn },
    });
    return payload;
}

async function listClassroomInstructors(classroomId, providerArn) {
    try {
        const { listClassroomRelationships: payload } = await executeRequest({
            operation: listClassroomRelationships,
            params: {
                input: { classroomId, providerArn, relationship: 'instructor' },
            },
        });
        return {
            classroomId,
            classroomUsers: payload.classroomUsers,
            emails: payload.classroomUsers.map((classroomUser) => classroomUser.email),
        };
    } catch (err) {
        if (err?.statusCode === HTTP_STATUS.NOT_IMPLEMENTED) {
            return {
                classroomId,
                emails: ['not-implemented@amazon.com'],
            };
        }
        throw err;
    }
}

async function listClassroomStudents(classroomId, providerArn) {
    try {
        const { listClassroomRelationships: payload } = await executeRequest({
            operation: listClassroomRelationships,
            params: {
                input: { classroomId, providerArn, relationship: 'student' },
            },
        });
        return {
            classroomId,
            classroomUsers: payload.classroomUsers,
            emails: payload.classroomUsers.map((classroomUser) => classroomUser.email),
        };
    } catch (err) {
        if (err?.statusCode === HTTP_STATUS.NOT_IMPLEMENTED) {
            return {
                classroomId,
                emails: ['not-implemented@amazon.com'],
            };
        }
        throw err;
    }
}

const listClassroomOperations = {
    listUserClassrooms,
    listProviderClassrooms,
};

async function listClassrooms({ providerArn, operation, nextToken, after }) {
    if (!providerArn) {
        return {
            classrooms: [],
            courseIds: [],
        };
    }
    const data = {
        classrooms: [],
        nextToken: null,
    };
    try {
        const response = await executeRequest({
            operation: listClassroomOperations[operation],
            params: {
                input: {
                    providerArn,
                    nextToken,
                    fullFilter: {
                        after,
                        attribute: 'endsOn',
                    },
                    maxResults: 10,
                },
            },
        });
        const payload = response[operation];
        data.classrooms = payload.classrooms;
        data.nextToken = payload.nextToken;
    } catch (err) {
        if (err?.statusCode === HTTP_STATUS.NOT_IMPLEMENTED) {
            data.classrooms = mockListClassrooms;
        } else {
            throw err;
        }
    }

    // modify the shape of the data to make it easily displayable by the Polaris Table component
    data.classrooms.forEach((classroom) => {
        classroom.classroomId = classroom.classroomArn.split('/').pop();
        classroom.startDate = moment
            .tz(classroom.startsOn * 1000, classroom.locationData.timezone)
            .format('MM/DD/YY');
        classroom.endsOn = moment
            .tz(classroom.endsOn * 1000, classroom.locationData.timezone)
            .format('MM/DD/YY');

        classroom.country = classroom.locationData?.physicalAddress?.country;
    });

    data.courseIds = uniq(data.classrooms.map(({ courseId }) => courseId));
    return data;
}

const getIngressUrl = async (input) => {
    const { getIngressUrl: payload } = await executeRequest({
        operation: getIngressUrlQuery,
        params: {
            input,
        },
    });
    return payload;
};

const updateTrainingPermissions = (input) => {
    return executeRequest({
        operation: updateTrainingPermissionsMutation,
        params: {
            input,
        },
    });
};

const subscribeToTrainingPermissions = (localSync) => {
    return executeRequest({
        operation: onUpdateTrainingPermissions,
        onSubscribe: localSync,
    });
};

const preloadLab = (input) =>
    executeRequest({
        operation: createStudentTrainings,
        params: {
            input,
        },
    });

const addStudentsToClassroomRoster = ({
    users,
    classroomId,
    providerArn,
    subProviderArn = undefined,
}) =>
    executeRequest({
        operation: addStudentsToClassroomRosterMutation,
        params: {
            input: {
                classroomId,
                users,
                providerArn,
                subProviderArn,
            },
        },
    });

const excludeUser = ({ classroomId, providerArn, user, relationship, clientRequestToken }) =>
    executeRequest({
        operation: excludeUserMutation,
        params: {
            input: {
                classroomId,
                providerArn,
                user,
                relationship,
                clientRequestToken,
            },
        },
    });

const getProviderReport = async (reportId, providerArn) => {
    const { getProviderReport: payload } = await executeRequest({
        operation: getProviderReportQuery,
        params: {
            input: {
                reportId,
                providerArn,
            },
        },
    });

    return payload.presignedUrl;
};

const getScheduledEvents = async (providerArn) => {
    const { getServiceSchedule: payload } = await executeRequest({
        operation: scheduledEventsQuery,
        params: {
            input: {
                providerArn,
            },
        },
    });
    return payload.schedules;
};

const listProviderReports = async (providerArn) => {
    let reports = [];
    let nextToken;
    do {
        const { listProviderReports: payload } = await executeRequest({
            operation: listProviderReportsQuery,
            params: {
                input: {
                    providerArn,
                    nextToken,
                },
            },
        });
        reports = reports.concat(payload.iltReports);
        nextToken = payload.nextToken;
    } while (nextToken);

    return reports;
};

const listProviderClassroomsV2 = async (param) => {
    const { listProviderClassroomsV2: payload } = await executeRequest({
        operation: listProviderClassroomsV2Query,
        params: {
            input: param,
        },
    });
    return payload;
};

const listInstructorClassroomsV2 = async (param) => {
    const { listInstructorClassroomsV2: payload } = await executeRequest({
        operation: listInstructorClassroomsV2Query,
        params: {
            input: param,
        },
    });
    return payload;
};

/**
 * Sends message to create or update a training configuration
 * @param param - A training configuration object
 * @returns The graphql payload containing the status code
 */
async function putTrainingConfiguration(param) {
    if (!param.context || !param.contentArn) return {};
    const { putTrainingConfiguration: payload } = await executeRequest({
        operation: putTrainingConfigurationMutation,
        params: {
            input: param,
        },
    });
    return payload;
}

/**
 * Sends message to get a training configuration
 * @param param - A training configuration key object
 * @returns The graphql payload containing the status code
 */
async function getTrainingConfiguration(param) {
    if (!param.context || !param.contentArn) return {};
    try {
        const { getTrainingConfiguration: payload } = await executeRequest({
            operation: getTrainingConfigurationQuery,
            params: {
                input: param,
            },
            options: { redirectOn404: false },
        });

        return payload;
    } catch (err) {
        if (err.statusCode === HTTP_STATUS.NOT_FOUND) {
            return undefined;
        }
        throw err;
    }
}

/**
 * Sends message to consolidate this email's resources
 * @returns The graphql payload containing the status code
 */
async function consolidateResources() {
    const { resetResources: payload } = await executeRequest({
        operation: resetResources,
    });
    return payload;
}

/**
 * Determines if user still needs to consolidate their resources
 * @returns true if user needs to consolidate their resources
 */
async function userRequiresReset() {
    const { requiresReset: payload } = await executeRequest({
        operation: requiresResetQuery,
    });
    return payload.requiresReset;
}

async function getCourseVirtualSKUInfo({ collectionVersionArn, providerArn, langLocale }) {
    const { courseVirtualSKUInfo: payload } = await executeRequest({
        operation: getCourseVirtualSKUInfoQuery,
        params: {
            input: {
                collectionVersionArn,
                providerArn,
                langLocale,
            },
        },
    });
    return payload;
}

async function getLearningActivity(classroomId, providerArn, learningActivityID) {
    const { getLearningActivity: payload } = await executeRequest({
        operation: getLearningActivityQuery,
        params: {
            input: {
                classroomId,
                providerArn,
                learningActivityID,
            },
        },
    });
    return payload;
}

async function getClassAttendance(classroomId, providerArn, learningActivityID) {
    const { getClassAttendance: payload } = await executeRequest({
        operation: getClassAttendanceQuery,
        params: {
            input: {
                classroomId,
                providerArn,
                learningActivityID,
            },
        },
    });
    return payload;
}

async function markAttendance({
    classroomId,
    providerArn,
    learningActivityID,
    deliverySessionId,
    learnerAttendance,
}) {
    const { markAttendance: payload } = await executeRequest({
        operation: markAttendanceMutation,
        params: {
            input: {
                classroomId,
                providerArn,
                learningActivityID,
                deliverySessionId,
                learnerAttendance,
            },
        },
    });
    return payload;
}

const fetchProviderAvailableLicenses = async ({
    providerArns,
    collectionVersionArn,
    langLocale,
}) => {
    let results = {};
    const processOneArn = async (arn) => {
        results[arn] = await getCourseVirtualSKUInfo({
            collectionVersionArn,
            providerArn: arn,
            langLocale,
        }).then((result) => result.availableQuantity);
    };
    await Promise.all(providerArns.map((arn) => processOneArn(arn)));
    return results;
};

// MOCKED

const endLabs = (input) =>
    executeRequest({
        operation: cancelStudentTrainings,
        params: { input },
    }).then(({ cancelStudentTrainings: payload }) => payload);

export {
    HTTP_STATUS,
    executeRequest,
    handleHttpErrorResponse,
    getHash,
    getAllCourseVersions,
    getCourseVersionDetails,
    getProviders,
    sendClientLog,
    getProviderData,
    getUserRoles,
    updateClassroom,
    createClassroom,
    addInstructorsToClassroom,
    listClassrooms,
    listClassroomInstructors,
    listClassroomStudents,
    getIngressUrl,
    updateTrainingPermissions,
    subscribeToTrainingPermissions,
    preloadLab,
    addStudentsToClassroomRoster,
    excludeUser,
    listAuthorizedCourses,
    listMajorVersions,
    getProviderReport,
    listProviderReports,
    listProviderClassroomsV2,
    listInstructorClassroomsV2,
    getScheduledEvents,
    endLabs,
    putTrainingConfiguration,
    getTrainingConfiguration,
    consolidateResources,
    userRequiresReset,
    getCourseVirtualSKUInfo,
    fetchProviderAvailableLicenses,
    getLearningActivity,
    getClassAttendance,
    markAttendance,
};
