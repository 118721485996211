import { Box } from '@amzn/awsui-components-react';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useIntl } from 'react-intl';
import { Link, useRouteError } from 'react-router-dom';

import { AppError, RUNTIME_ERROR_NAME } from '@/utils/error';

import messages from './ErrorPage.messages';

import type { MessageDescriptor } from 'react-intl';

type RouteError = {
    data: string; // 'Error: No route matches URL "/asdf"'
    error: Error; //  No route matches URL "/asdf"
    internal: boolean; // true
    status: number; // 404
    statusText: string; // "Not found"
};

const getMessage = (
    error: RouteError,
): {
    heading: MessageDescriptor;
    subHeading: MessageDescriptor;
    message: MessageDescriptor;
    unhandled: boolean;
} => {
    switch (error.status) {
        case 404:
            return {
                heading: messages.pageHeading,
                subHeading: messages.pageNotFound,
                message: messages.pageAdvice,
                unhandled: false,
            };
        default:
            return {
                heading: messages.genericErrorSomethingWentWrong,
                subHeading: messages.genericErrorHeading,
                message: messages.genericErrorTryAgain,
                unhandled: true,
            };
    }
};

const ErrorPage = () => {
    const { formatMessage } = useIntl();
    const error = useRouteError() as RouteError;
    const { heading, subHeading, message, unhandled } = getMessage(error);

    useEffect(() => {
        if (!unhandled) return;

        new AppError(error.toString(), { type: RUNTIME_ERROR_NAME });
    }, [unhandled, error]);

    return (
        <>
            <Helmet>
                <title>{formatMessage(messages.pageTitle)}</title>
            </Helmet>
            <Box padding='l' textAlign='center'>
                <Box variant='h1'>{formatMessage(heading)}</Box>
                <h2>{formatMessage(subHeading)}</h2>
                <p>{formatMessage(message)}</p>
                <Link to='/'>{formatMessage(messages.pageLink)}</Link>
            </Box>
        </>
    );
};

export default ErrorPage;
