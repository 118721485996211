export const isConsoleReady = (labStatus) => labStatus === 'CONSOLE_AVAILABLE';
export const isPreloading = (labStatus) => PRELOADING_LAB_STATUSES.includes(labStatus);
export const isPreloadFailed = (training) =>
    training?.fulfillmentError?.name === 'BunsenProvisionFailure' ||
    (training?.fulfillmentStatus === 'error' &&
        training?.fulfillmentError?.name !== 'TooManyActiveLabs');
export const isExceedingLabLimit = (name) => name === 'TooManyActiveLabs';
export const isNotPreloading = (training) =>
    isPreloadFailed(training) ||
    isExceedingLabLimit(training?.fulfillmentError?.name) ||
    (training?.metaData?.labStatus && !isPreloading(training?.metaData?.labStatus));

const NO_LAB_STATE = 'NO_LAB';

const filterCurrentBatch = (trainings, labId, batchId) =>
    trainings.filter(
        (training) => training.arn === labId && training?.requestClientToken?.includes(batchId),
    );

export const isAllConsoleReady = (trainings, labId, batchId) => {
    const filteredTraining = filterCurrentBatch(trainings, labId, batchId);
    return (
        filteredTraining.length > 0 &&
        filteredTraining.every((training) => isConsoleReady(training?.metaData?.labStatus))
    );
};

export const computeTableItems = (studentTrainings = [], labId) => {
    let accumulator = {};
    for (let i = 0; i < studentTrainings.length; i++) {
        let { userKey, studentNumber, arn, lastUpdated, firstName, lastName, metaData } =
            studentTrainings[i];
        if (!accumulator[userKey]) {
            // no previous item found
            if (arn !== labId) {
                accumulator[userKey] = {
                    userKey,
                    studentNumber,
                    state: NO_LAB_STATE,
                };
                if (firstName && lastName) {
                    accumulator[userKey].firstName = firstName;
                    accumulator[userKey].lastName = lastName;
                }
            } else {
                accumulator[userKey] = studentTrainings[i];
                if (metaData?.labRegions?.length) {
                    accumulator[userKey].labRegion = metaData.labRegions[0];
                }
            }
        } else {
            const shouldReplaceWithItemInAcummulator =
                arn === labId &&
                (accumulator[userKey].state === NO_LAB_STATE ||
                    accumulator[userKey].lastUpdated < lastUpdated);
            if (shouldReplaceWithItemInAcummulator) {
                accumulator[userKey] = studentTrainings[i];
                if (metaData?.labRegions?.length) {
                    accumulator[userKey].labRegion = metaData.labRegions[0];
                }
            }
        }
    }
    return Object.values(accumulator);
};

const POOLED_TYPE = 'POOLED_SPL';
export const isPooledLab = ({ trainingType, requestClientToken }) => {
    const token = requestClientToken ?? '';
    return trainingType ? trainingType === POOLED_TYPE : token?.includes(POOLED_TYPE);
};

export const computeTableForPooledLabs = ({ labArn, trainings = [], roster = [] }) => {
    const trainingsForLab = trainings.filter(({ arn }) => arn === labArn);
    return roster
        .sort((a, b) => a.email.localeCompare(b.email))
        .map(({ userKey, email, firstName, lastName }, index) => {
            const latestUserTraining = trainingsForLab
                .filter((training) => training.userKey === userKey)
                .sort((a, b) => a.lastUpdated - b.lastUpdated)
                .pop();
            return (
                latestUserTraining ?? {
                    email,
                    firstName,
                    lastName,
                    userKey: userKey ?? `unassigned-${index}`,
                    state: NO_LAB_STATE,
                }
            );
        });
};

export const LabStatus = Object.freeze({
    NoLab: 'no-lab',
    Preloading: 'provisioning',
    Preloaded: 'preloaded',
    Ended: 'ended',
    Failed: 'failed',
    Unknown: 'unkown',
    Ready: 'ready',
    ExceedLabLimit: 'exceedLabLimit',
    Running: 'running',
    Expired: 'expired',
});

export const PRELOADING_LAB_STATUSES = [
    'PENDING',
    'INITIALIZING',
    'PROVISIONING',
    'RESOURCES_READY',
];

export const getLabStatus = (lab) => {
    const hasMetaData = !!lab.metaData;
    let labStatus;

    if (lab.state === NO_LAB_STATE) {
        labStatus = LabStatus.NoLab;
    } else if (
        lab.fulfillmentStatus === 'error' &&
        lab.fulfillmentError?.name === 'TooManyActiveLabs'
    ) {
        labStatus = LabStatus.ExceedLabLimit;
    } else if (lab.fulfillmentStatus === 'error') {
        labStatus = LabStatus.Failed;
    } else if (hasMetaData && isPreloading(lab.metaData.labStatus)) {
        labStatus = LabStatus.Preloading;
    } else if (
        hasMetaData &&
        (lab.metaData.labStatus === 'CONSOLE_AVAILABLE' || lab.metaData.labStatus === 'READY')
    ) {
        if (lab.bunsenLabStatus === 'RUNNING') {
            labStatus = LabStatus.Running;
        } else {
            labStatus = LabStatus.Preloaded;
        }
    } else if (hasMetaData && lab.metaData.labStatus === 'SESSION_ENDED') {
        if (lab.bunsenLabStatus === 'EXPIRED') {
            labStatus = LabStatus.Expired;
        } else {
            labStatus = LabStatus.Ended;
        }
    } else {
        labStatus = LabStatus.Unknown;
    }

    return labStatus;
};
