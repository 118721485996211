import { I18nProvider } from '@amzn/awsui-components-react/polaris/i18n';
import messages from '@amzn/awsui-components-react/polaris/i18n/messages/all.all';
import { useState, useEffect, createContext, useContext, useRef } from 'react';
import { IntlProvider } from 'react-intl';

import appTranslations from '@/i18n';
import { sendClientLog } from '@/modules';
import localStorageDatabase from '@/modules/localStorageDb';

const LocaleContext = createContext(null);

export const LangLocaleProvider = ({ children, translations = appTranslations }) => {
    const [locale, localeSet] = useState(localStorageDatabase.selectedLocale());
    const firstRender = useRef(true);

    useEffect(() => {
        if (firstRender.current) {
            firstRender.current = false;
            return;
        }
        localStorageDatabase.set({ id: 'locale', value: locale });
        sendClientLog({
            type: 'AppClick',
            metrics: { InstructorLanguageSelect: [1, 'Count'] },
            attributes: { locale },
        });
    }, [locale]);

    return (
        <LocaleContext.Provider value={{ locale, localeSet }}>
            <I18nProvider locale={locale} messages={[messages]}>
                <IntlProvider locale={locale} messages={translations[locale]}>
                    {children}
                </IntlProvider>
            </I18nProvider>
        </LocaleContext.Provider>
    );
};

export const useLocale = () => useContext(LocaleContext);
