import messages from './IdentityErrorPage.message';

export const IdpNames = {
    AWSBuilderID: 'AWSBuilderID',
    AmazonEmployeeSSO: 'AmazonFederate',
    OTP: 'OTP',
    LoginWithAmazon: 'LoginWithAmazon',
    AWSPartnerNetwork: 'AWSPartnerNetwork',
    Unknown: 'IdentityProviderNotKnown',
};

// Get the formatted IdP Name based on the public_provider_name attribute from JWToken.
export const getFormattedIdpName = (idpName, formatMessage) => {
    switch (idpName) {
        case IdpNames.AmazonEmployeeSSO:
            return formatMessage(messages.signInAmazonEmployeeSSO);
        case IdpNames.AWSBuilderID:
            return formatMessage(messages.signInAWSBuilderID);
        case IdpNames.OTP:
            return formatMessage(messages.signInOTP);
        case IdpNames.LoginWithAmazon:
            return formatMessage(messages.signInLWA);
        case IdpNames.AWSPartnerNetwork:
            return formatMessage(messages.signInAPN);
        case IdpNames.Unknown:
            return formatMessage(messages.signInUnknown);
        default:
            // The providerName for Organization SSO varies.
            return formatMessage(messages.signInOrgSSO);
    }
};
