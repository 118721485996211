/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const clientLog = /* GraphQL */ `
  mutation ClientLog($input: Event!) {
    clientLog(input: $input) {
      statusCode
      __typename
    }
  }
`;
export const updateTrainingPermissions = /* GraphQL */ `
  mutation UpdateTrainingPermissions($input: PermissionInput!) {
    updateTrainingPermissions(input: $input) {
      classroomId
      activeTrainings
      status
      __typename
    }
  }
`;
export const createClassroom = /* GraphQL */ `
  mutation CreateClassroom($input: CreateClassroomInput!) {
    createClassroom(input: $input) {
      classroomId
      classroomArn
      __typename
    }
  }
`;
export const createClassroomV2 = /* GraphQL */ `
  mutation CreateClassroomV2($input: CreateClassroomV2Input!) {
    createClassroomV2(input: $input) {
      classroomId
      classroomArn
      __typename
    }
  }
`;
export const updateClassroom = /* GraphQL */ `
  mutation UpdateClassroom($input: UpdateClassroomInput!) {
    updateClassroom(input: $input) {
      classroomId
      classroomArn
      langLocale
      courseId
      startsOn
      endsOn
      timezone
      locationType
      virtualUrl
      addressLine1
      addressLine2
      city
      state
      country
      postalCode
      classCapacity
      __typename
    }
  }
`;
export const updateClassroomV2 = /* GraphQL */ `
  mutation UpdateClassroomV2($input: UpdateClassroomV2Input!) {
    updateClassroomV2(input: $input) {
      classroomId
      classroomArn
      langLocale
      courseId
      startsOn
      endsOn
      timezone
      locationType
      virtualUrl
      addressLine1
      addressLine2
      city
      state
      country
      postalCode
      classCapacity
      __typename
    }
  }
`;
export const cancelClassroom = /* GraphQL */ `
  mutation CancelClassroom($classroomId: String!, $providerArn: String) {
    cancelClassroom(classroomId: $classroomId, providerArn: $providerArn) {
      classroomArn
      providerArn
      langLocale
      courseId
      startsOn
      endsOn
      locationData {
        timezone
        locationType
        virtualUrl
        physicalAddress {
          addressLine1
          addressLine2
          city
          state
          postalCode
          country
          __typename
        }
        source
        __typename
      }
      courseTitle
      createdByEmail
      instructorEmails
      __typename
    }
  }
`;
export const acceptInvitations = /* GraphQL */ `
  mutation AcceptInvitations {
    acceptInvitations {
      acceptedInvitations {
        email
        invitationArn
        relationship
        resourceArn
        status
        __typename
      }
      __typename
    }
  }
`;
export const createClassroomRelationships = /* GraphQL */ `
  mutation CreateClassroomRelationships(
    $input: CreateClassroomRelationshipsInput!
  ) {
    createClassroomRelationships(input: $input) {
      statusCode
      __typename
    }
  }
`;
export const addStudentsToClassroomRoster = /* GraphQL */ `
  mutation AddStudentsToClassroomRoster(
    $input: AddStudentsToClassroomRosterInput!
  ) {
    addStudentsToClassroomRoster(input: $input) {
      statusCode
      __typename
    }
  }
`;
export const createStudentTrainings = /* GraphQL */ `
  mutation CreateStudentTrainings($input: CreateStudentTrainingsInput!) {
    createStudentTrainings(input: $input) {
      batchId
      created
      labPoolBatchInfo {
        batchId
        createdTime
        __typename
      }
      __typename
    }
  }
`;
export const excludeUser = /* GraphQL */ `
  mutation ExcludeUser($input: ExcludeUserInput!) {
    excludeUser(input: $input) {
      email
      invitationArn
      relationship
      resourceArn
      status
      __typename
    }
  }
`;
export const cancelStudentTrainings = /* GraphQL */ `
  mutation CancelStudentTrainings($input: CancelStudentTrainingsInput!) {
    cancelStudentTrainings(input: $input) {
      batchId
      created
      __typename
    }
  }
`;
export const putTrainingConfiguration = /* GraphQL */ `
  mutation PutTrainingConfiguration($input: PutTrainingConfigurationInput!) {
    putTrainingConfiguration(input: $input) {
      statusCode
      __typename
    }
  }
`;
export const resetResources = /* GraphQL */ `
  mutation ResetResources {
    resetResources {
      statusCode
      __typename
    }
  }
`;
export const markAttendance = /* GraphQL */ `
  mutation MarkAttendance($input: MarkAttendanceInput!) {
    markAttendance(input: $input) {
      attendanceRecords {
        id
        version
        attendanceStatus
        attendanceDurationPercentage
        learner {
          id
          emailAddress
          firstName
          lastName
          __typename
        }
        amsDeliverySession {
          id
          startTime
          endTime
          learningActivity {
            pk
            attendedCounter
            activity_audience
            activity_modality
            activity_name
            activity_status
            activity_type
            class_size
            course_name
            delivery_address_1
            delivery_address_2
            delivery_city
            delivery_country
            delivery_geo
            delivery_language
            delivery_postal_code
            delivery_region
            delivery_state
            delivery_timezone
            program
            record_type
            classrooms_student_url
            classrooms_arn
            lms_type
            v_ilt_type
            v_ilt_id
            v_ilt_meeting_id
            registrationCounter
            __typename
          }
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
