import { defineMessages } from 'react-intl.macro';

const messages = defineMessages({
    provisionPoolModalTitle: {
        id: 'provisionPoolModal.title',
        defaultMessage: 'Provision lab pool',
    },
    provisionPoolModalMessage: {
        id: 'provisionPoolModal.message',
        defaultMessage:
            'Provisioned labs will be assigned to students in {region}, when they launch their labs from the student page.',
    },
    provisionPoolModalEdit: {
        id: 'provisionPoolModal.edit',
        defaultMessage: 'Edit',
    },
    provisionPoolModalCancel: {
        id: 'provisionPoolModal.cancel',
        defaultMessage: 'Cancel',
    },
    provisionPoolModalSubmit: {
        id: 'provisionPoolModal.submit',
        defaultMessage: 'Submit',
    },
    provisionPoolModalInputConstraint: {
        id: 'provisionPoolModal.inputConstraint',
        defaultMessage: 'The available amount of labs to preload is {preloadLimit}.',
    },

    provisionPoolModalCreateInputLabel: {
        id: 'provisionPoolModal.create.inputLabel',
        defaultMessage: 'Number of labs to provision',
    },

    provisionPoolModalEditInputLabel: {
        id: 'provisionPoolModal.edit.inputLabel',
        defaultMessage: 'Additional labs to provision',
    },
    provisionPoolModalEditInputDescription: {
        id: 'provisionPoolModal.edit.inputDescription',
        defaultMessage: 'This is in addition to previous preloads.',
    },
});

export default messages;
