import {
    Alert,
    Modal,
    Textarea,
    Box,
    Button,
    SpaceBetween,
    FormField,
    Select,
} from '@amzn/awsui-components-react';
import throttle from 'lodash/throttle';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import {
    createStudentListEditState,
    getDisabledCapacityInputHint,
} from '@/components/classForm/ClassForm.utils';
import { useProvider } from '@/data/ProviderContext';
import { useGilmoreEvents } from '@/data/useGilmoreEvents';
import { messages as utilMessages } from '@/index.messages';
import { paths } from '@/routing/paths';
import { useFlags, calculateReservedLicenses } from '@/utils';

import { messages } from './StudentRosterModal.messages';

import './StudentRosterModal.scss';

const THROTTLE_MS = 500;

const StudentRosterModal = ({
    openStudentListModal,
    closeStudentListModal,
    providersMap,
    setProvidersMap,
    addStudentsToRoster,
    studentRosterErrors,
    studentRosterCapacityExceededErrors,
    setStudentRosterCapacityExceededErrors,
    classCapacitySizeExceededBy,
    setClassCapacitySizeExceededBy,
    roster,
    capacityUpdateWithRoster,
    gilmoreLicenseError,
    setGilmoreLicenseError,
    showBtnSpinner,
    classroomId,
    addToRosterSucceeded,
    setAddToRosterSucceeded,
    resetErrorBanners,
    shouldResetStudentListInput,
    setShouldResetStudentListInput,
}) => {
    const { formatMessage } = useIntl();
    const { thereIsActiveEvent, mostRecent: mostRecentEvent } = useGilmoreEvents();
    const provider = useProvider();
    const classCapacityExceeded = classCapacitySizeExceededBy > 0;
    const studentRosterCapacityExceeded = studentRosterCapacityExceededErrors.length > 0;
    const requiresSubProvider = provider?.requiresSubProvider;
    const flags = useFlags();

    const providerOptions = provider.requiresSubProvider
        ? provider.subProviderData.map((data) => ({
              label: data.providerName,
              value: data.providerName,
          }))
        : [{ label: provider.name, value: provider.name }];

    const [selectedProvider, setSelectedProvider] = useState(providerOptions[0]);
    const [providerCapacity, setProviderCapacity] = useState(0);
    const [licensesReserved, setLicensesReserved] = useState(0);
    const [availableCapacity, setAvailableCapacity] = useState(0);

    const resetOverCapacityAndLicenseError = () => {
        setClassCapacitySizeExceededBy(0);
        setGilmoreLicenseError(false);
        setStudentRosterCapacityExceededErrors([]);
    };

    const hasCapacity = availableCapacity > 0;
    const DisabledCapacityAlertMessage = () => (
        <>
            {availableCapacity === 0 && formatMessage(messages.classCapacityNoUnassignedSeats)}
            {availableCapacity === 1 && formatMessage(messages.classCapacitySingleUnassignedSeats)}
            {availableCapacity > 1 &&
                formatMessage(messages.classCapacityMultipleUnassignedSeats, {
                    num: availableCapacity,
                })}{' '}
            {getDisabledCapacityInputHint(mostRecentEvent, formatMessage, {
                plannedMessage: messages.classCapacityPlannedOutageDisabled,
                unplannedMessage: messages.classCapacityUnplannedOutageDisabled,
            })}
        </>
    );
    const addStudentsToRosterHandler = useMemo(
        () => throttle(() => addStudentsToRoster(selectedProvider.value), THROTTLE_MS),
        [addStudentsToRoster, selectedProvider.value],
    );

    useEffect(() => {
        setProviderCapacity(providersMap[selectedProvider.value].capacity);
        const studentCountForProvider = providersMap[selectedProvider.value].studentCount;
        const rosterForProvider = requiresSubProvider
            ? roster.filter(
                  (student) =>
                      student.eKitLicensedProviderArn === providersMap[selectedProvider.value].arn,
              )
            : roster;
        setLicensesReserved(calculateReservedLicenses(rosterForProvider, studentCountForProvider));
        setAvailableCapacity(providerCapacity - licensesReserved);
    }, [
        selectedProvider,
        providerCapacity,
        licensesReserved,
        providersMap,
        requiresSubProvider,
        roster,
    ]);

    useEffect(
        () => () => {
            addStudentsToRosterHandler.cancel();
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    );

    useEffect(() => {
        if (shouldResetStudentListInput) {
            setProvidersMap({
                ...providersMap,
                ...Object.fromEntries([
                    [
                        selectedProvider.value,
                        {
                            ...providersMap[selectedProvider.value],
                            studentSubListValue: '',
                        },
                    ],
                ]),
            });
            setShouldResetStudentListInput(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shouldResetStudentListInput]);

    let textareaRef = useRef();

    useEffect(() => {
        if (studentRosterErrors?.length && typeof textareaRef?.focus === 'function') {
            textareaRef.focus();
        }
    }, [studentRosterErrors, textareaRef]);
    return (
        <Modal
            onDismiss={closeStudentListModal}
            closeAriaLabel={formatMessage(messages.closeModalButtonLabel)}
            data-testid='student-roster-modal'
            visible={openStudentListModal}
            header={formatMessage(messages.headerLabel)}
            footer={
                <Footer
                    requiresSubProvider={requiresSubProvider}
                    onCloseStudentListModal={closeStudentListModal}
                    addStudentsToRosterHandler={addStudentsToRosterHandler}
                    classCapacitySizeExceededBy={classCapacitySizeExceededBy}
                    capacityUpdateWithRoster={capacityUpdateWithRoster}
                    gilmoreLicenseError={gilmoreLicenseError}
                    thereIsActiveEvent={thereIsActiveEvent}
                    showBtnSpinner={showBtnSpinner}
                    resetOverCapacityAndLicenseError={resetOverCapacityAndLicenseError}
                    formatMessage={formatMessage}
                    classroomId={classroomId}
                    providersMap={providersMap}
                    studentRosterCapacityExceededErrors={studentRosterCapacityExceededErrors}
                />
            }
        >
            <SpaceBetween direction='vertical' size='s'>
                {!!(thereIsActiveEvent && !classCapacityExceeded) && (
                    <Alert
                        statusIconAriaLabel={formatMessage(utilMessages.warningLabel)}
                        type='warning'
                    >
                        <DisabledCapacityAlertMessage />
                    </Alert>
                )}

                {!!classCapacityExceeded && (
                    <Alert statusIconAriaLabel={formatMessage(utilMessages.infoLabel)}>
                        {formatMessage(
                            requiresSubProvider
                                ? messages.classCapacityExceededMessageWithSubProvider
                                : messages.classCapacityExceededMessage,
                            {
                                size: classCapacitySizeExceededBy,
                            },
                        )}
                    </Alert>
                )}

                {!!gilmoreLicenseError && (
                    <Alert
                        type='error'
                        statusIconAriaLabel={formatMessage(utilMessages.errorLabel)}
                    >
                        {formatMessage(messages.gilmoreLicenseError)}
                    </Alert>
                )}

                {!!(!classCapacityExceeded && (!hasCapacity || studentRosterCapacityExceeded)) && (
                    <Alert
                        type='warning'
                        statusIconAriaLabel={formatMessage(utilMessages.warningLabel)}
                    >
                        {formatMessage(
                            requiresSubProvider
                                ? messages.classLimitBodyWithSubProvider
                                : messages.classLimitBody,
                        )}
                    </Alert>
                )}

                {!!addToRosterSucceeded && (
                    <Alert
                        type='success'
                        statusIconAriaLabel={formatMessage(utilMessages.successLabel)}
                    >
                        {formatMessage(messages.addStudentsToRosterSucceed)}
                    </Alert>
                )}

                <FormField
                    label={formatMessage(messages.bodyTitle)}
                    description={formatMessage(messages.bodyInstructions)}
                    errorText={
                        studentRosterErrors.length > 0 &&
                        studentRosterErrors.map((error, i) => (
                            <Box key={`${error.email}-${i}`}>
                                {error.email} {formatMessage(messages.invalidEmail)}
                            </Box>
                        ))
                    }
                    constraintText={formatMessage(
                        requiresSubProvider
                            ? messages.classCapacityBodySubProvider
                            : messages.classCapacityBody,
                        { classCapacity: availableCapacity },
                    )}
                >
                    {flags.enableMultiGilmoreIdPhase2 && (
                        <div className='select-wrapper'>
                            <Select
                                selectedOption={selectedProvider}
                                onChange={({ detail }) => {
                                    setSelectedProvider(detail.selectedOption);
                                    setAddToRosterSucceeded(false);
                                    resetErrorBanners();
                                }}
                                options={providerOptions}
                                disabled={!provider.requiresSubProvider}
                            />
                        </div>
                    )}

                    <Textarea
                        onChange={({ detail }) =>
                            setProvidersMap({
                                ...providersMap,
                                ...Object.fromEntries([
                                    [
                                        selectedProvider.value,
                                        {
                                            ...providersMap[selectedProvider.value],
                                            studentSubListValue: detail.value,
                                        },
                                    ],
                                ]),
                            })
                        }
                        ref={(ref) => (textareaRef = ref)}
                        value={providersMap[selectedProvider.value].studentSubListValue}
                        ariaLabel='student-emails'
                        placeholder={formatMessage(messages.placeholder)}
                        disabled={classCapacityExceeded || gilmoreLicenseError}
                    />
                </FormField>
            </SpaceBetween>
        </Modal>
    );
};

const Footer = ({
    requiresSubProvider,
    onCloseStudentListModal,
    addStudentsToRosterHandler,
    classCapacitySizeExceededBy,
    capacityUpdateWithRoster,
    gilmoreLicenseError,
    thereIsActiveEvent,
    showBtnSpinner,
    resetOverCapacityAndLicenseError,
    formatMessage,
    classroomId,
    providersMap,
    studentRosterCapacityExceededErrors,
}) => {
    const navigate = useNavigate();
    const studentRosterCapacityExceeded = studentRosterCapacityExceededErrors.length > 0;
    let onClassCapacityExceedButton = requiresSubProvider ? (
        <Button
            onClick={() =>
                navigate(
                    `${paths.classEditPage(classroomId)}`,
                    createStudentListEditState(classroomId, providersMap, true),
                )
            }
            loading={showBtnSpinner}
            disabled={thereIsActiveEvent}
            variant='primary'
        >
            {formatMessage(messages.goToEditClass)}
        </Button>
    ) : (
        <Button
            onClick={capacityUpdateWithRoster}
            loading={showBtnSpinner}
            disabled={thereIsActiveEvent}
            variant='primary'
        >
            {formatMessage(messages.updateClassCapacity)}
        </Button>
    );

    return (
        <div>
            <Box float='right'>
                <SpaceBetween direction='horizontal' size='xs'>
                    {gilmoreLicenseError ? (
                        <>
                            <Button
                                href='https://aws.gilmoreglobal.com/login'
                                iconAlign='left'
                                iconName='external'
                                target='blank'
                                variant='link'
                            >
                                {formatMessage(messages.gilmoreLicenseStoreFrontLink)}
                            </Button>
                            <Button onClick={resetOverCapacityAndLicenseError} variant='primary'>
                                {formatMessage(messages.editStudentListButton)}
                            </Button>
                        </>
                    ) : (
                        <>
                            {classCapacitySizeExceededBy > 0 ? (
                                <>
                                    <Button
                                        onClick={resetOverCapacityAndLicenseError}
                                        variant='link'
                                    >
                                        {formatMessage(messages.editStudentListButton)}
                                    </Button>
                                    {onClassCapacityExceedButton}
                                </>
                            ) : (
                                <>
                                    <Button onClick={onCloseStudentListModal} variant='normal'>
                                        {formatMessage(messages.closeModalButtonText)}
                                    </Button>
                                    <Button
                                        onClick={addStudentsToRosterHandler}
                                        loading={showBtnSpinner}
                                        variant='primary'
                                        disabled={studentRosterCapacityExceeded}
                                    >
                                        {formatMessage(messages.addListModalButtonText)}
                                    </Button>
                                </>
                            )}
                        </>
                    )}
                </SpaceBetween>
            </Box>
        </div>
    );
};

export default StudentRosterModal;
