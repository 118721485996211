import { Header, Container, Table } from '@amzn/awsui-components-react';
import { useIntl } from 'react-intl';

import messages from './DeliverySessionList.messages';

const isValidDate = (date) => {
    return date instanceof Date && !isNaN(date);
};

const formatDateTimeRange = (start_timestamp, end_timestamp, timezone) => {
    if (!start_timestamp || !end_timestamp) {
        return { dateRange: '-', timeRange: '-' };
    }
    const startDate = new Date(start_timestamp * 1000);
    const endDate = new Date(end_timestamp * 1000);
    if (!isValidDate(startDate) || !isValidDate(endDate)) {
        return { dateRange: '-', timeRange: '-' };
    }
    const dateOptions = {
        month: 'long',
        day: 'numeric',
        year: 'numeric',
        timeZone: timezone,
    };
    const timeOptions = {
        hour: 'numeric',
        minute: '2-digit',
        hour12: true,
        timeZone: timezone,
    };
    const startDateStr = startDate.toLocaleDateString(undefined, dateOptions);
    const endDateStr = endDate.toLocaleDateString(undefined, dateOptions);
    const startTimeStr = startDate.toLocaleTimeString(undefined, timeOptions);
    const endTimeStr = endDate.toLocaleTimeString(undefined, timeOptions);
    const isSameDay = startDateStr === endDateStr;
    const dateRange = isSameDay ? startDateStr : `${startDateStr} - ${endDateStr}`;
    const timeRange = `${startTimeStr} - ${endTimeStr}`;

    return {
        dateRange,
        timeRange,
    };
};

const DeliverySessionList = ({ sessions = [], timezone }) => {
    const { formatMessage } = useIntl();
    const columnDefinitions = [
        {
            id: 'date',
            header: formatMessage(messages.tableHeaderDate),
            cell: ({ dateRange }) => dateRange || '-',
        },
        {
            id: 'time',
            header: formatMessage(messages.tableHeaderTime),
            cell: ({ timeRange }) => timeRange || '-',
        },
    ];

    return (
        <Container
            header={
                <Header variant='h2'>
                    {formatMessage(messages.tableTitle, {
                        numDays: sessions.length,
                        dayUnitStr: sessions.length === 1 ? 'day' : 'days',
                    })}
                </Header>
            }
            disableContentPaddings
            noGutters
        >
            <Table
                resizableColumns
                variant='borderless'
                wrapLines
                columnDefinitions={columnDefinitions}
                items={sessions.map((session) =>
                    formatDateTimeRange(session.start_timestamp, session.end_timestamp, timezone),
                )}
            ></Table>
        </Container>
    );
};

export default DeliverySessionList;
