import { BreadcrumbGroup } from '@amzn/awsui-components-react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { messages } from '@/index.messages';

const Breadcrumbs = ({ items }) => {
    const { formatMessage } = useIntl();
    const navigate = useNavigate();

    const onBreadcrumbClick = (e) => {
        e.preventDefault();
        navigate(e.detail.href);
    };

    return (
        <BreadcrumbGroup
            items={items}
            onClick={onBreadcrumbClick}
            ariaLabel={formatMessage(messages.breadcrumbsLabel)}
        />
    );
};

export default Breadcrumbs;
