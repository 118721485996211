import { Button } from '@amzn/awsui-components-react';
import { Link } from 'react-router-dom';

import type { ButtonProps } from '@amzn/awsui-components-react';
import type { PropsWithChildren } from 'react';
import type { LinkProps } from 'react-router-dom';

type LinkButtonProps = Pick<LinkProps, 'to'> & ButtonProps;

export const LinkButton = ({
    children,
    to,
    disabled,
    ...props
}: PropsWithChildren<LinkButtonProps>) => {
    const onClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        if (disabled) {
            e.preventDefault();
            return;
        }
    };
    return (
        <Link to={to} onClick={onClick}>
            <Button disabled={disabled} {...props}>
                {children}
            </Button>
        </Link>
    );
};
