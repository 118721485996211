import { Modal, Box, SpaceBetween, Button, FormField, Input } from '@amzn/awsui-components-react';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { useClassroomData } from '@/data/useClassroomData';
import { useFlags } from '@/utils';
import { useDecodedParams } from '@/utils/paramHooks';
import { usePooledLabs } from '@/utils/pooledLabs';

import environment from '@/.config/environment';

import messages from './index.messages';

import type { InputProps, NonCancelableCustomEvent } from '@amzn/awsui-components-react';
import type { MessageDescriptor } from 'react-intl';

const {
    provisionPoolModalTitle,
    provisionPoolModalCreateInputLabel,
    provisionPoolModalEditInputLabel,
    provisionPoolModalEditInputDescription,
    provisionPoolModalMessage,
    provisionPoolModalEdit,
    provisionPoolModalCancel,
    provisionPoolModalSubmit,
    provisionPoolModalInputConstraint,
} = messages;

interface ProvisionPoolModalProps {
    buttonText: MessageDescriptor;
    inputLabel: MessageDescriptor;
    inputDescription?: MessageDescriptor;
    selectedRegion: string;
    activePooledLabsCount: number;
}

const PENDING_AMOUNT = '...';

const ProvisionPoolModal = ({
    buttonText,
    inputLabel,
    inputDescription,
    selectedRegion,
    activePooledLabsCount,
}: ProvisionPoolModalProps) => {
    const { pooledLabsPerLearner = 1 } = environment(window);
    const { formatMessage } = useIntl();
    const { classroomId } = useDecodedParams();
    const { classData } = useClassroomData(classroomId);
    const { classCapacity = 0 } = classData?.classroom ?? {};
    const maxLabs = classCapacity * pooledLabsPerLearner - activePooledLabsCount;
    const noLabsLeft = maxLabs <= 0;

    const flags = useFlags();
    const [isVisible, isVisibleSet] = useState(false);

    const [inputValue, inputValueSet] = useState(PENDING_AMOUNT);

    const { request } = usePooledLabs();

    const onChange = (e: NonCancelableCustomEvent<InputProps.ChangeDetail>) => {
        inputValueSet(Math.min(maxLabs, Math.max(+e.detail.value, 1)).toString());
    };
    const onSubmit = async () => {
        if (noLabsLeft) return;
        await request({ targetCount: +inputValue });
        isVisibleSet(false);
    };

    const handleClose = () => {
        isVisibleSet(false);
    };

    useEffect(() => {
        if (inputValue === PENDING_AMOUNT) {
            // initial set when data is coming in
            if (maxLabs > 0) inputValueSet(`${maxLabs}`);
        } else {
            // happens when data was late, making sure the input doesn't exceed maxLabs
            if (parseInt(inputValue) > maxLabs) inputValueSet(`${maxLabs}`);
        }
    }, [classCapacity, inputValue, maxLabs]);

    if (!flags.pooledLabs || !classCapacity) return null;

    return (
        <>
            <Button onClick={() => isVisibleSet(true)}>{formatMessage(buttonText)}</Button>
            <Modal
                onDismiss={handleClose}
                visible={isVisible}
                data-testid={`provision-pool-modal--${isVisible ? 'visible' : 'hidden'}`}
                footer={
                    <Box float='right'>
                        <SpaceBetween direction='horizontal' size='xs'>
                            <Button variant='link' onClick={handleClose}>
                                {formatMessage(provisionPoolModalCancel)}
                            </Button>
                            <Button variant='primary' onClick={onSubmit} disabled={noLabsLeft}>
                                {formatMessage(provisionPoolModalSubmit)}
                            </Button>
                        </SpaceBetween>
                    </Box>
                }
                header={formatMessage(provisionPoolModalTitle)}
            >
                <SpaceBetween direction='vertical' size='m'>
                    <FormField
                        label={formatMessage(inputLabel)}
                        description={inputDescription ? formatMessage(inputDescription) : undefined}
                        constraintText={formatMessage(provisionPoolModalInputConstraint, {
                            preloadLimit: maxLabs,
                        })}
                    >
                        <Input
                            type='number'
                            value={inputValue}
                            onChange={onChange}
                            inputMode='numeric'
                            data-testid='pooled-labs-input'
                            disabled={noLabsLeft}
                            step={1}
                        />
                    </FormField>
                    <Box variant='p'>
                        {formatMessage(provisionPoolModalMessage, { region: selectedRegion })}
                    </Box>
                </SpaceBetween>
            </Modal>
        </>
    );
};

export const CreateProvisionPoolModal = (
    props: Omit<ProvisionPoolModalProps, 'buttonText' | 'inputLabel' | 'inputDescription'>,
) => (
    <ProvisionPoolModal
        buttonText={provisionPoolModalTitle}
        inputLabel={provisionPoolModalCreateInputLabel}
        {...props}
    />
);

export const EditProvisionPoolModal = (
    props: Omit<ProvisionPoolModalProps, 'buttonText' | 'inputLabel' | 'inputDescription'>,
) => (
    <ProvisionPoolModal
        buttonText={provisionPoolModalEdit}
        inputLabel={provisionPoolModalEditInputLabel}
        inputDescription={provisionPoolModalEditInputDescription}
        {...props}
    />
);
