import { Button } from '@amzn/awsui-components-react';
import { useIntl } from 'react-intl';

import messages from './CancelClass.messages';

import './CancelClass.scss';

const CancelClass = ({ isCancellable, openConfirmationDialog }) => {
    const { formatMessage } = useIntl();

    const onClick = () => openConfirmationDialog(true);

    return isCancellable === null ? null : (
        <Button
            variant='normal'
            className='class-detail__action-btn'
            disabled={isCancellable !== true}
            onClick={onClick}
            data-testid='cancel-class-button'
        >
            {formatMessage(messages.cancelClassButtonText)}
        </Button>
    );
};

export default CancelClass;
