import { Box, Header, Pagination, Table } from '@amzn/awsui-components-react';
import { useMemo, useReducer, useEffect } from 'react';
import { useIntl } from 'react-intl';

import { useProvider } from '@/data/ProviderContext';
import { useAppNotifications } from '@/utils/appNotifications';
import { useAcceptInvitations } from '@/utils/useAcceptInvitations';
import { useUserInfo } from '@/utils/userInfo';

import { useClasslistingPreference } from './classlistPreferenceManager';
import {
    getClassListingTableEventDispatcher,
    classListingTableStateProcessor,
    getClassListingTableInitialState,
} from './classlistStateManager';
import { ClassroomListPreferences, getConfiguration } from './classlistTableConfig';
import ClassroomListFilter from './ClassroomListFilter';
import { useClassroomQuery } from './classroomListingHooks';
import { messages } from './ClassTable.messages';

const ClassListingTable = ({ archivedOnly = false }) => {
    const providerArn = useProvider()?.arn;
    const { userIsTrainingCoordinator, isLoading: isLoadingUserInfo } = useUserInfo();
    const intl = useIntl();
    const acceptedInvitations = useAcceptInvitations();
    const { tablePreferences, preferenceUpdater: setTablePreferences } = useClasslistingPreference({
        userIsTrainingCoordinator,
        archivedOnly,
        isLoadingUserInfo,
    });
    const { addNotification } = useAppNotifications();

    const classTableConfig = useMemo(
        () => getConfiguration(intl, userIsTrainingCoordinator, archivedOnly),
        [intl, userIsTrainingCoordinator, archivedOnly],
    );

    const [state, dispatch] = useReducer(
        classListingTableStateProcessor,
        {
            classTableConfig,
            archivedOnly,
        },
        getClassListingTableInitialState,
    );

    const eventDispatcher = useMemo(() => {
        return getClassListingTableEventDispatcher(dispatch, addNotification);
    }, [dispatch, addNotification]);

    const {
        classroomList,
        isLoading,
        isSuccess: isClassroomFetchingSuccessful,
        isTotalClassroomCountExact,
        totalClassroomCount,
        filterValueAggregation,
        classroomListRefetcher,
    } = useClassroomQuery({
        providerArn,
        pageNumber: state.currentPage,
        pageSize: tablePreferences.pageSize,
        sortByColumn: state.sorting.column,
        sortDescending: state.sorting.isDescending,
        filterOptions: state.filtering.activeFilters,
        filterFieldsRequestedForAggregation: state.filtering.filterAttributesToUpdate,
        isLoadingUserInfo,
    });

    useEffect(() => {
        if (acceptedInvitations && acceptedInvitations.length && isClassroomFetchingSuccessful) {
            new Promise((res) => setTimeout(res, 3000)).then(classroomListRefetcher);
        }
    }, [acceptedInvitations, isClassroomFetchingSuccessful, classroomListRefetcher]);

    // Don't render the class listing table until user detail is loaded since final table configuration
    // and query depends on user role.
    if (isLoadingUserInfo) return null;
    return (
        <Table
            columnDefinitions={classTableConfig.columnDefinitions}
            items={classroomList}
            loading={isLoading || isLoadingUserInfo}
            loadingText='Loading resources'
            sortingDisabled={isLoading}
            sortingDescending={state.sorting.isDescending}
            sortingColumn={state.sorting.column}
            onSortingChange={(event) => eventDispatcher.sortingChange(event)}
            resizableColumns={true}
            columnDisplay={tablePreferences.visibleContent.map((id) => ({
                id: id.id ?? id,
                visible: true,
            }))}
            variant='container'
            wrapLines={tablePreferences.wrapLines}
            trackBy={(item) => item.classroomId}
            stickyHeader
            renderAriaLive={({ firstIndex, lastIndex, totalItemsCount }) =>
                intl.formatMessage(messages.tableAriaStatus, {
                    firstIndex,
                    lastIndex,
                    totalItemsCount,
                })
            }
            empty={
                <Box textAlign='center' color='inherit'>
                    <Box padding={{ bottom: 's' }} variant='p' color='inherit'>
                        {intl.formatMessage(messages.noClasses)}
                    </Box>
                </Box>
            }
            filter={
                <ClassroomListFilter
                    filterConfig={classTableConfig.filterConfig}
                    classListingEventDispatcher={eventDispatcher}
                    filterOptionValues={filterValueAggregation}
                />
            }
            header={
                <Header
                    counter={
                        isTotalClassroomCountExact
                            ? `(${totalClassroomCount ?? 0})`
                            : `(${totalClassroomCount}+)`
                    }
                >
                    {intl.formatMessage(
                        archivedOnly
                            ? messages.classListArchivedHeading
                            : messages.classListActiveUpcomingHeading,
                    )}
                </Header>
            }
            pagination={
                <Pagination
                    pagesCount={Math.ceil(totalClassroomCount / tablePreferences.pageSize)}
                    currentPageIndex={state.currentPage}
                    disabled={isLoading}
                    onChange={(event) => eventDispatcher.paginationChange(event)}
                    openEnd={!isTotalClassroomCountExact}
                    ariaLabels={classTableConfig.paginationLabel}
                />
            }
            preferences={
                <ClassroomListPreferences
                    preferences={tablePreferences}
                    setPreferences={setTablePreferences}
                    pageSizeOptions={classTableConfig.pageSizeOptions}
                    visibleContentOptions={classTableConfig.visibleColumnOptions}
                    formatMessage={intl.formatMessage}
                />
            }
        />
    );
};

export default ClassListingTable;
