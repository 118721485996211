const ROOT_URL = '/';
const PROVIDERS_PAGE = '/providers';
const CLASSES_PAGE = '/classes';
const LANDING_PAGE = CLASSES_PAGE;
const CALLBACK_PATH = '/callback';

// This makes sure to fully decode a string and then encode it 1 level
export const safeEncode = (value = '', depth = 0): string => {
    const decoded = decodeURIComponent(value);
    if (decoded === value || depth > 5) return encodeURIComponent(decoded);
    return safeEncode(decoded, depth + 1);
};

export const paths = {
    root: ROOT_URL,
    landingPage: LANDING_PAGE,
    providersPage: PROVIDERS_PAGE,
    classListPage: CLASSES_PAGE,

    classPageV1: (classId: string) => `/class/${safeEncode(classId)}`,
    classDetailPageV1: (classId: string) => `/class/${safeEncode(classId)}/detail`,
    classEditPageV1: (classId: string) => `/class/${safeEncode(classId)}/edit`,
    classAttendanceV1: (classId: string) => `/class/${safeEncode(classId)}/attendance`,
    labPageV1: (classId: string, labNumber: string | number) =>
        `/class/${safeEncode(classId)}/labs/${labNumber}`,

    classPage: (classId: string) => `${CLASSES_PAGE}/${safeEncode(classId)}`,
    classDetailPage: (classId: string) => `${CLASSES_PAGE}/${safeEncode(classId)}/detail`,
    classEditPage: (classId: string) => `${CLASSES_PAGE}/${safeEncode(classId)}/edit`,
    classAttendance: (classId: string) => `${CLASSES_PAGE}/${safeEncode(classId)}/attendance`,
    labPage: (classId: string, labNumber: string | number) =>
        `${CLASSES_PAGE}/${safeEncode(classId)}/labs/${labNumber}`,
};

// A user should be returned to their target page (if defined) with a fallback to the landing page
export const redirect = (globals: typeof window) => {
    const landingPage = LANDING_PAGE;
    let pathFromStorage = globals.sessionStorage.getItem('redirect_pathname');
    // NB: If path from storage is assigned to callback for some reason,
    // The URL will be set to `/callback` as an invalid route
    if (pathFromStorage === CALLBACK_PATH) {
        globals.sessionStorage.setItem('redirect_pathname', PROVIDERS_PAGE);
        pathFromStorage = PROVIDERS_PAGE;
    }
    return pathFromStorage && pathFromStorage !== ROOT_URL ? pathFromStorage : landingPage;
};
export const parameters = {
    classroomId: ':classroomId',
    labNumber: ':labNumber',
};

export const isClassPage = (path = '') =>
    path !== '/class/new' && new RegExp('/class/[a-zA-Z0-9]+').test(path);

// !!! NOTE: This function is for testing purposes only !!!
export const parameterizeUrl = (url = '') => {
    let handled = false;
    return url.includes('/new')
        ? url
        : url
              .replace(/\/classe?s?\/(.+)\/labs\/(.+)/, () => {
                  handled = true;
                  return '/classes/:classroomId/labs/:labNumber';
              })
              .replace(/\/classe?s?\/(.+)\/(.+)/, (match, _g1, g2) => {
                  if (handled) return match;
                  handled = true;
                  return `/classes/:classroomId/${g2}`;
              })
              .replace(/\/classe?s?\/[^/]+$/, (match) =>
                  handled ? match : '/classes/:classroomId',
              );
};
