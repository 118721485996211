import type { Training } from '@/data/trainings/types';

interface ParsedTrainings {
    provisioning: Training[];
    unassigned: Training[];
    assigned: Training[];
    ended: Training[];
    failed: Training[];
    unknown: Training[];
}

const activeLabStatuses = [
    'PENDING',
    'INITIALIZING',
    'PROVISIONING',
    'RESOURCES_READY',
    'CONSOLE_AVAILABLE',
    'READY',
];
export const isTrainingAnActivePooledLab = ({ metaData, trainingType }: Training): boolean =>
    trainingType === 'POOLED_SPL' && activeLabStatuses.includes(metaData?.labStatus ?? '');

export const parseStudentTrainingsData = (trainings: Training[]): ParsedTrainings => {
    return trainings
        .filter((training) => training.state !== 'NO_LAB')
        .reduce(
            (acc: ParsedTrainings, training) => {
                const { labStatus } = training.metaData ?? {};
                const { provisioning, unassigned, assigned, ended, failed, unknown } = acc;
                if (training.fulfillmentStatus === 'error') {
                    failed.push(training);
                } else {
                    switch (labStatus) {
                        case 'PENDING':
                        case 'INITIALIZING':
                        case 'PROVISIONING':
                        case 'RESOURCES_READY':
                            provisioning.push(training);
                            break;
                        case 'SESSION_ENDED':
                            ended.push(training);
                            break;
                        case 'CONSOLE_AVAILABLE':
                        case 'READY':
                            if (!training.email) {
                                unassigned.push(training);
                            } else {
                                assigned.push(training);
                            }
                            break;
                        default:
                            unknown.push(training);
                            break;
                    }
                }
                return {
                    provisioning,
                    unassigned,
                    assigned,
                    ended,
                    failed,
                    unknown,
                };
            },
            {
                provisioning: [],
                unassigned: [],
                assigned: [],
                ended: [],
                failed: [],
                unknown: [],
            },
        );
};
