import { Box, ContentLayout, Header, Tabs } from '@amzn/awsui-components-react';
import { useState } from 'react';
import { Helmet } from 'react-helmet';
import { useIntl } from 'react-intl';

import { ClassListingTable, ReportTable, GilmoreHealthBanner, LinkButton } from '@/components';
import { Layout } from '@/containers/Layout';
import { useProvider } from '@/data/ProviderContext';
import { paths } from '@/routing/paths';
import { useUserInfo } from '@/utils/userInfo';

import messages from './ClassListPage.messages';

import './ClassListPage.css';

const ClassListPageV2 = () => {
    const { formatMessage } = useIntl();
    const providerName = useProvider()?.name;
    const { userIsTrainingCoordinator } = useUserInfo();

    const [renderArchivedClasses, setRenderArchivedClasses] = useState(false);
    const [renderReports, setRenderReports] = useState(false);

    const navigationTabs = [
        {
            id: 'class-list',
            label: formatMessage(messages.classesLabel),
            content: (
                <Box data-testid='activeClassListingContainer'>
                    <ClassListingTable key='class-list' />
                </Box>
            ),
        },
        {
            id: 'archived-class-list',
            label: formatMessage(messages.archivedLabel),
            content: (
                <Box data-testid='archivedClassListingContainer'>
                    <LazyLoadedArchivedClassListingTable
                        key='lazy-archived-class-list'
                        rendered={renderArchivedClasses}
                    />
                </Box>
            ),
        },
    ];

    if (userIsTrainingCoordinator) {
        navigationTabs.push({
            id: 'report-dash',
            label: formatMessage(messages.reportDashLabel),
            content: (
                <Box>
                    <LazyLoadedReportsTable rendered={renderReports} />
                </Box>
            ),
        });
    }

    const initialNavTab = navigationTabs[0].id;
    const [activeNavTab, setActiveNavTab] = useState(initialNavTab);

    // When a tab is selected, always activate it, then turn on rendering for the corresponding content, either archived-class-list tab
    // or report-dash table so that the component is rendered.
    const tabChangeHandler = (activatedTabId) => {
        console.info(`Activating ${activatedTabId}`);
        setActiveNavTab(activatedTabId);
        if (activatedTabId === 'archived-class-list') {
            setRenderArchivedClasses((previousState) => {
                console.info(`Setting renderArchivedClasses with previousState=${previousState}`);
                if (!previousState) {
                    return true;
                } else {
                    return previousState;
                }
            });
        } else if (activatedTabId === 'report-dash') {
            setRenderReports((previousState) => {
                if (!previousState) {
                    return true;
                } else {
                    return previousState;
                }
            });
        }
    };

    return (
        <Layout>
            <Helmet>
                <title>{formatMessage(messages.pageTitle)}</title>
            </Helmet>
            <GilmoreHealthBanner />
            <ContentLayout
                header={
                    <Header
                        variant='h1'
                        actions={
                            <LinkButton to={paths.classPage('new')}>
                                {formatMessage(messages.createClass)}
                            </LinkButton>
                        }
                    >
                        {providerName}
                    </Header>
                }
            >
                <Tabs
                    activeTabId={activeNavTab}
                    ariaLabel={formatMessage(messages.navigationTabLabel)}
                    onChange={(event) => tabChangeHandler(event.detail.activeTabId)}
                    tabs={navigationTabs}
                />
            </ContentLayout>
        </Layout>
    );
};

/**
 * Wrapper to lazy load class listing table only when user selects the tab that displayed archived classroom list
 */
const LazyLoadedArchivedClassListingTable = ({ rendered = false }) => {
    if (rendered) {
        return <ClassListingTable key='archived-class-list' archivedOnly={true} />;
    } else {
        return null;
    }
};

/**
 * Wrapper to lazy load reports table only when user selects the tab that displayed reports
 */
const LazyLoadedReportsTable = ({ rendered = false }) => {
    if (rendered) {
        return <ReportTable />;
    } else {
        return null;
    }
};

export default ClassListPageV2;
