import { Spinner } from '@amzn/awsui-components-react';
import { Suspense } from 'react';
import { Await } from 'react-router-dom';

import './Loader.css';

import type { PropsWithChildren } from 'react';
import type { AwaitProps } from 'react-router-dom';

interface LoaderProps {
    isLoading: boolean;
    hasError?: boolean;
    fallback?: () => JSX.Element;
    message?: string;
    backgroundColor?: string;
}

const LoaderElement = ({
    backgroundColor,
    message,
}: Pick<LoaderProps, 'backgroundColor' | 'message'>) => (
    <div className='loading-wrapper' data-testid='loader' style={{ backgroundColor }}>
        {message ? <p>{message}</p> : null}
        <Spinner size='large' />
    </div>
);

const Loader = ({
    isLoading,
    hasError,
    fallback,
    children,
    message,
    backgroundColor,
}: PropsWithChildren<LoaderProps>) => {
    if (hasError) {
        return fallback ? fallback() : null;
    }

    return isLoading ? (
        <LoaderElement message={message} backgroundColor={backgroundColor} />
    ) : (
        children
    );
};

export const SuspenseLoader = (props: AwaitProps) => (
    <Suspense fallback={<LoaderElement />}>
        <Await {...props} />
    </Suspense>
);

export default Loader;
