export const getClassroomWithInstructors = /* GraphQL */ `
    query GetClassroomWithInstructors(
        $classroomId: String!
        $providerArn: String!
        $withStudentRoster: Boolean!
        $withSubProviderCapacity: Boolean!
    ) {
        listClassroomRelationships(
            input: {
                classroomId: $classroomId
                providerArn: $providerArn
                relationship: instructor
            }
        ) {
            classroomUsers {
                email
            }
        }
        studentRoster: listClassroomRelationships(
            input: { classroomId: $classroomId, providerArn: $providerArn, relationship: student }
        ) @include(if: $withStudentRoster) {
            classroomUsers {
                email
                firstName
                lastName
                hasAcceptedInvitation
                userKey
                eKitLicensedProviderArn
            }
        }
        getClassroom(classroomId: $classroomId, providerArn: $providerArn) {
            classroom {
                accessType
                classroomId
                classroomArn
                langLocale
                courseId
                providerArn
                courseTitle
                courseAbstract
                courseDescription
                courseLevel
                startsOn
                endsOn
                isCancellable
                learningActivityID
                locationData {
                    timezone
                    locationType
                    virtualUrl
                    physicalAddress {
                        addressLine1
                        addressLine2
                        city
                        state
                        postalCode
                        country
                    }
                    source
                }
                studentGuide {
                    custom_vbid
                }
                callsToAction {
                    locationType
                    url
                    label
                    actionType
                    icon
                    iconPosition
                }
                accessCodes {
                    accessCode
                    accessCodeCapacity
                    accessCodeCapacityUsed
                }
                classCapacity
                subProviderCapacity @include(if: $withSubProviderCapacity) {
                    subProviderArn
                    capacity
                    subProviderStudentCount
                }
                studentCount
                supportedRegions
                createdBy
            }
            course {
                courseId
                title
                description
                langLocale
                abstract
                level
            }
            content {
                contentId
                title
                description
                level
                duration
                durationUnit
                isActive
                supportedRegions
            }
            jamTrainings {
                trainings {
                    context
                    trainingId
                    trainingType
                    contentId
                    isActive
                    requestClientToken
                    fulfillmentStatus
                    fulfillmentError {
                        message
                        code
                    }
                    created
                    lastUpdate
                    metaData {
                        eventId
                        eventCode
                        eventStatus
                        testCloneEventId
                        testCloneEventCode
                        testCloneEventSuffix
                        startsOn
                        endsOn
                        timezone
                        maxTeamSize
                        maxExpectedParticipants
                        eventURL
                        testEventURL
                        adminEventURL
                    }
                }
                nextToken
            }
        }
    }
`;

export const getLearningActivity = /* GraphQL */ `
    query QueryLearningActivity($input: GetLearningActivityInput!) {
        getLearningActivity(input: $input) {
            pk
            activity_status
            lms_type
            delivery_sessions {
                id
                start_timestamp
                end_timestamp
            }
            customers {
                customer_name
            }
        }
    }
`;

export const getClassAttendance = /* GraphQL */ `
    query QueryClassAttendance($input: GetClassAttendanceInput!) {
        getClassAttendance(input: $input) {
            learningActivityAttendanceRecords {
                learner {
                    id
                    emailAddress
                    lastName
                }
                learningActivityAttendanceStatus
                deliverySessionAttendanceRecords {
                    id
                    attendanceStatus
                    attendanceDurationPercentage
                    amsDeliverySession {
                        id
                        startTime
                    }
                }
            }
        }
    }
`;
