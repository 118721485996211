import { supportedLocales } from '../i18n';

class InMemoryLocalStorageDatabase {
    #db = {};

    constructor(storage) {
        this.storage = storage;
    }

    set({ id, value }) {
        this.#db[id] = { id, value };
        this.storage.setItem(id, value);
    }

    get(id) {
        const item = this.#db[id];

        if (item) {
            return item.value;
        }

        const value = this.storage.getItem(id);
        this.#db[id] = { id, value };

        return value;
    }
}
export class LocalStorageDB extends InMemoryLocalStorageDatabase {
    constructor(globals = window) {
        super(globals.localStorage);
    }

    selectedLocale() {
        const storedLocale = this.get('locale');
        return supportedLocales.find((supported) => supported === storedLocale) ?? 'en-US';
    }
}

const localStorageDatabase = new LocalStorageDB();
export default localStorageDatabase;
