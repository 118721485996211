import { Header, Pagination, Table } from '@amzn/awsui-components-react';
import { useState } from 'react';
import { useIntl } from 'react-intl';

import { CopyButton } from '@/components/copyButton/CopyButton';
import { getCustomLandingPageURL } from '@/utils';

import messages from './AccessCodeTable.messages';
import { getColumnLabelBase, computeViewableItems } from './AccessCodeTable.utils';

import './AccessCodeTable.scss';

const COLUMN_ID = {
    accessCode: 'accessCode',
    accessCodeCapacityUsed: 'accessCodeCapacityUsed',
};

const buildColumnDefinitions = (
    formatMessage,
    studentUrl,
    customLandingPageUrl,
    singleAccessCode,
    hasStudentRoster,
) => [
    {
        id: COLUMN_ID.accessCode,
        header: (
            <div>
                <span>
                    {formatMessage(
                        hasStudentRoster
                            ? messages.studentRosterLabel
                            : singleAccessCode
                              ? messages.multiUseCodeLabel
                              : messages.singleAccessCodeLabel,
                    )}
                </span>
                &nbsp;&ndash;&nbsp;
                <span className='access-code-description'>
                    {formatMessage(messages.accessCodeDescription)}
                </span>
            </div>
        ),
        cell: ({ accessCode }) => {
            const studentAccessUrl = hasStudentRoster
                ? `${studentUrl}`
                : `${studentUrl}?accessCode=${encodeURIComponent(accessCode)}`;
            return (
                <div>
                    <div className='access-code-cell'>
                        <a
                            className='access-code-url'
                            href={studentAccessUrl}
                            aria-label={formatMessage(messages.studentAccessUrlLabel)}
                        >
                            {studentAccessUrl}
                        </a>
                        <CopyButton label={messages.copyCodeLabel}>{studentAccessUrl}</CopyButton>
                    </div>
                    {customLandingPageUrl && (
                        <div className='landing-page-cell'>
                            <a
                                className='landing-page-url'
                                href={customLandingPageUrl}
                                aria-label={formatMessage(messages.studentLandingPageUrlLabel)}
                            >
                                {customLandingPageUrl}
                            </a>
                            <CopyButton label={messages.copyLandingPageUrlLabel}>
                                {customLandingPageUrl}
                            </CopyButton>
                        </div>
                    )}
                </div>
            );
        },
    },
];

const accessCodeColumns = (formatMessage, getColumnLabel, singleAccessCode) => [
    {
        id: COLUMN_ID.accessCodeCapacityUsed,
        header: formatMessage(messages.useCodeLabel),
        sortingField: 'accessCodeCapacityUsed',
        label: singleAccessCode
            ? undefined
            : (sortState) =>
                  getColumnLabel(
                      sortState,
                      COLUMN_ID.accessCodeCapacityUsed,
                      formatMessage(messages.useCodeLabel),
                  ),
        cell: ({ accessCodeCapacityUsed, accessCodeCapacity }) =>
            `${accessCodeCapacityUsed}/${accessCodeCapacity}`,
    },
];

const AccessCodeTable = ({ accessCodes, studentUrl, hasStudentRoster }) => {
    const PAGE_SIZE = 5;
    const { formatMessage } = useIntl();
    const [pageIndex, pageIndexSet] = useState(1);
    const [sortDescending, sortDescendingSet] = useState(false);
    const getColumnLabel = getColumnLabelBase(
        formatMessage(messages.sortedAscending),
        formatMessage(messages.sortedDescending),
    );

    if (!accessCodes || accessCodes.length === 0) {
        if (hasStudentRoster) {
            accessCodes = [''];
        } else {
            return null;
        }
    }

    const singleAccessCode = accessCodes?.length === 1;
    const customLandingPageUrl = getCustomLandingPageURL();

    const columnDefinitions = buildColumnDefinitions(
        formatMessage,
        studentUrl,
        customLandingPageUrl,
        singleAccessCode,
        hasStudentRoster,
    );

    if (!hasStudentRoster) {
        columnDefinitions.push(
            ...accessCodeColumns(formatMessage, getColumnLabel, singleAccessCode),
        );
    }

    const pagesCount = Math.ceil(accessCodes?.length / PAGE_SIZE);
    const tableItems = computeViewableItems(accessCodes, sortDescending, PAGE_SIZE, pageIndex);
    return (
        <Table
            className='access-codes-table'
            header={
                <Header variant='h2'>
                    {formatMessage(
                        hasStudentRoster ? messages.studentRosterHeader : messages.accessCodeHeader,
                    )}
                </Header>
            }
            pagination={
                singleAccessCode ? null : (
                    <Pagination
                        onChange={({ detail }) => pageIndexSet(detail.currentPageIndex)}
                        currentPageIndex={pageIndex}
                        pagesCount={pagesCount}
                    />
                )
            }
            items={tableItems}
            columnDefinitions={columnDefinitions}
            onSortingChange={() => sortDescendingSet((prev) => !prev)}
        />
    );
};

export default AccessCodeTable;
