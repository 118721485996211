import { defineMessages } from 'react-intl.macro';

const messages = defineMessages({
    tableTitle: {
        id: 'deliverySessionList.tableTitle',
        defaultMessage: 'Sessions ({numDays} {dayUnitStr})',
    },
    tableHeaderDate: {
        id: 'deliverySessionList.tableHeaderDate',
        defaultMessage: 'Date',
    },
    tableHeaderTime: {
        id: 'deliverySessionList.tableHeaderTime',
        defaultMessage: 'Time',
    },
});

export default messages;
