import { useContext } from 'react';
import { useQuery } from 'react-query';

import { GilmoreHealthContext } from '@/data/GilmoreHealthContext';
import { getScheduledEvents } from '@/modules';
import { useFlags } from '@/utils/flags';

import { useProvider } from './ProviderContext';

export const useGilmoreEvents = () => {
    const flags = useFlags();
    const { gilmoreEventDataSet, ...rest } = useContext(GilmoreHealthContext);

    const provider = useProvider();
    const isPartner = provider?.type !== 'DIRECT';
    const checkForScheduledEvents = isPartner && flags?.scheduledEvents;
    useQuery(['getScheduledEvents', provider?.arn], () => getScheduledEvents(provider.arn), {
        enabled: checkForScheduledEvents,
        refetchOnWindowFocus: true,
        onSuccess: gilmoreEventDataSet,
        onError: console.error,
    });

    return rest;
};
